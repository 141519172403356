import logo from './aquatrade.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          AquaTrade(TM) is a water trading platform, giving California farmers and property owners instant access to a secure and frictionless Marketplace to buy and sell water and water credits.
        </p>
      </header>
    </div>
  );
}

export default App;
